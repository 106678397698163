import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { constrainPoint } from "@fullcalendar/vue";

export default function AttendanceMusterList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: "name", sortable: false, label: "Name" },
        { key: "1", sortable: false, label: "1" },
        { key: "2", sortable: false, label: "2" },
        { key: "3", sortable: false, label: "3" },
        { key: "4", sortable: false, label: "4" },
        { key: "5", sortable: false, label: "5" },
        { key: "6", sortable: false, label: "6" },
        { key: "7", sortable: false, label: "7" },
        { key: "8", sortable: false, label: "8" },
        { key: "9", sortable: false, label: "9" },
        { key: "10", sortable: false, label: "10" },
        { key: "11", sortable: false, label: "11" },
        { key: "12", sortable: false, label: "12" },
        { key: "13", sortable: false, label: "13" },
        { key: "14", sortable: false, label: "14" },
        { key: "15", sortable: false, label: "15" },
        { key: "16", sortable: false, label: "16" },
        { key: "17", sortable: false, label: "17" },
        { key: "18", sortable: false, label: "18" },
        { key: "19", sortable: false, label: "19" },
        { key: "20", sortable: false, label: "20" },
        { key: "21", sortable: false, label: "21" },
        { key: "22", sortable: false, label: "22" },
        { key: "23", sortable: false, label: "23" },
        { key: "24", sortable: false, label: "24" },
        { key: "25", sortable: false, label: "25" },
        { key: "26", sortable: false, label: "26" },
        { key: "27", sortable: false, label: "27" },
        { key: "28", sortable: false, label: "28" },
        { key: "29", sortable: false, label: "29" },
        { key: "30", sortable: false, label: "30" },
        { key: "31", sortable: false, label: "31" },
    ];
    
    const perPage = ref(10);
    const totalAttendanceMusterList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const attendanceMustersMetaData = ref([]);
    const attendanceMusterStatusOption = ref([]);
    const yearsListOptions = ref([]);
    const userHashid = ref(0);
    const month = ref(new Date().getMonth()+1);
    const year = ref(new Date().getFullYear());
    let $list_add = {}; 
    let $length = ''; 

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalAttendanceMusterList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
            userHashid,
            month,
            year
        ],
        () => {
            refetchData();
        }
    );

    const fetchAttendanceMusterList = (ctx, callback) => {
      
        store
            .dispatch("app-attendance_musters/fetchAttendanceMusters", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                userHashid: userHashid.value,
                month: month.value,
                year: year.value,
            })
            .then(response => {
                const attendance_muster = response.data.data;
                // console.log(tableColumns)
                // console.log(tableColumns.length)
                // if(attendance_muster.length)
                // {
                //     $list_add = Object.keys(attendance_muster[0]).length - 28
                //     $length = 28
                //     console.log(Object.keys(attendance_muster[0]).length);
                //     console.log($list_add);
                //     for(let i = 0; i < $list_add; i++)
                //     {
                //         console.log('here');
                //         $length = $length + 1
                //         tableColumns.push({ key: $length.toString(), sortable: false, label: $length.toString() })
                //     }
                //     console.log(tableColumns.length)
                // }
                const { total } = response.data.meta;
                attendanceMusterStatusOption.value = response.data.status;
                yearsListOptions.value = response.data.year;

                callback(attendance_muster);
                totalAttendanceMusterList.value = total;
                attendanceMustersMetaData.value = attendance_muster;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching attendance_musters",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });
               toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error fetching attendance_musters",
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                      },
                      {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                      })
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchAttendanceMusterList,
        tableColumns,
        perPage,
        currentPage,
        totalAttendanceMusterList,
        attendanceMustersMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        attendanceMusterStatusOption,
        statusFilter,
        yearsListOptions,
        month,
        year,
        userHashid
    };
}
